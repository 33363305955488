import * as types from './actionTypes'
import CentralizeApi from 'api/CentralizeApi'

export function setCentralizesData(centralizes) {
    return {type: types.SET_CENTRALIZES, centralizes}
}

export function getCentralizes() {
    return async function(dispatch) {
        let exp = await CentralizeApi.getCentralizes()
        return dispatch(setCentralizesData(exp.data))
    }
}

export function setCentralizeOrg(id) {
    return async function(dispatch) {
        let result = await CentralizeApi.setCentralizeOrg(id)
        if(result.data) {
            localStorage.setItem("auth_token", result.data)
            setTimeout(() => {
                window.location = '/dashboard'
            }, 200)
        }
        // return dispatch(setCentralizesData(exp.data))
    }
}