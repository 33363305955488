import * as user from './userAction'
import * as breadcrumb from './breadcrumbAction'
import * as servicepermission from './servicepermissionAction'
import * as serviceexpired from './serviceExpiredAction'
import * as orgdisabled from './orgDisabledAction'
import * as notification from './notificationAction'
import * as centralize from './centralizeAction'
import * as notice from './noticeAction'

const actions = {
    ...servicepermission,
    ...user,
    ...breadcrumb,
    ...serviceexpired,
    ...orgdisabled,
    ...notification,
    ...centralize,
    ...notice,
}

export default actions