
import { SET_NOTICES } from './../../actions/actionTypes';
import initialState from './initialState';

export default function noticeReducer(state = initialState.notices, action) {
    switch(action.type) {
        case SET_NOTICES:
            return action.notices;
        default: 
            return state;
    }
}
