const prod =
    process.env.NODE_ENV === "production" ? true : false

export const SERVICE_API = prod
    ? "https://api.just-id.com"
    : "https://api.just-id.com"
    // : "http://localhost:8082"
export const BASE_API = prod
    ? "https://app.just-id.com"
    : "https://app.just-id.com"
export const DOMAIN = prod ? "app.just-id.com" : "localhost"
export const COOKIE_DOMAIN =
    prod === "production" ? ".just-id.com" : "localhost"
