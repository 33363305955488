import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions/index'

export const useNotice = () => {
    const dispatch = useDispatch()
    const notices = useSelector((state) => state.notices)

    const getNotices = () => {
        dispatch(actions.getNotices())
    }
    return { notices, getNotices }
}