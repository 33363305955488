import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions/index'

export const useOrgDisabled = () => {
    const dispatch = useDispatch()
    const orgDisables = useSelector((state) => state.orgDisables)

    const getOrgDisabled = () => {
        dispatch(actions.getOrgDisabled())
    }
    return { orgDisables, getOrgDisabled }
}