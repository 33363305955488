import {SET_SERVICE_EXPIRED} from './../../actions/actionTypes';
import initialState from './initialState';

export default function serviceExpiredReducer(state = initialState.serviceExpires, action) {
    switch(action.type) {
        case SET_SERVICE_EXPIRED:
            return action.serviceExpires;
        default: 
            return state;
    }
}
