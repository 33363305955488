import * as types from './actionTypes'
import ServiceApi from 'api/ServiceApi'

export function setServiceExpiredData(services) {
    return {type: types.SET_SERVICE_EXPIRED, serviceExpires: services}
}

export function getServiceExpired() {
    return async function(dispatch) {
        let exp = await ServiceApi.getServiceExpired()
        return dispatch(setServiceExpiredData(exp.data))
    }
}