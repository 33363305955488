import * as types from './actionTypes'
import ServiceApi from 'api/ServiceApi'

export function setNoticesData(notices) {
    return {type: types.SET_NOTICES, notices}
}

export function getNotices() {
    return async function(dispatch) {
        let notice = await ServiceApi.getNotices()
        return dispatch(setNoticesData(notice.data))
    }
}
