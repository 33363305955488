import {SET_UNREAD_NOTIFICATION} from './../../actions/actionTypes';
import initialState from './initialState';

export default function unreadReducer(state = initialState.unread, action) {
    switch(action.type) {
        case SET_UNREAD_NOTIFICATION:
            return action.unread;
        default: 
            return state;
    }
}
