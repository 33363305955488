import * as types from './actionTypes'
import NotificationApi from 'api/NotificationApi'

export function setNotificationData(notifications) {
    return {type: types.SET_NOTIFICATIONS, notifications}
}

export function setUnreadNotificationData(unread) {
    return {type: types.SET_UNREAD_NOTIFICATION, unread}
}

export function getNotifications() {
    return async function(dispatch) {
        let noti = await NotificationApi.getNotifications()
        dispatch(setUnreadNotificationData(noti.unread))
        return dispatch(setNotificationData(noti.docs))
    }
}

export function readNotification(id) {
    return async function(dispatch) {
        await NotificationApi.updateNotification(id, {readed: true})
        let noti = await NotificationApi.getNotifications()
        dispatch(setUnreadNotificationData(noti.unread))
        return dispatch(setNotificationData(noti.docs))
    }
}