import { httpClient } from 'utils/HttpClient'

class ServiceApi {

    async getServiceExpired() {
        return await (await httpClient.get('/get-service-expired')).data
    }

    async getOrgDisabled() {
        return await (await httpClient.get('/get-org-disabled')).data
    }

    async getNotices() {
        return await (await httpClient.get('/notices')).data
    }
}

export default new ServiceApi()