import {SET_CENTRALIZES} from './../../actions/actionTypes';
import initialState from './initialState';

export default function centralizeReducer(state = initialState.centralizes, action) {
    switch(action.type) {
        case SET_CENTRALIZES:
            return action.centralizes;
        default: 
            return state;
    }
}
