import {SET_NOTIFICATIONS} from './../../actions/actionTypes';
import initialState from './initialState';

export default function notificationReducer(state = initialState.notifications, action) {
    switch(action.type) {
        case SET_NOTIFICATIONS:
            return action.notifications;
        default: 
            return state;
    }
}
