import { httpClient } from 'utils/HttpClient'

class CentralizeApi {

    async getCentralizes() {
        return await (await httpClient.get('/centralizes')).data
    }

    async setCentralizeOrg(id) {
        return await (await httpClient.post('/centralizes/change-org', { app_org_id: id })).data
    }
}

export default new CentralizeApi()