import React, { useState, useEffect } from 'react'
import AuthenticateApi from 'api/AuthenticateApi'
import dayjs from "dayjs"
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from 'rsuite/lib/Button'
import { setToken } from 'services/token'
import { validatePassword } from 'utils/Validate'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const ATTEMPT_EXPIRED = 'attempt_expired'
const LOGIN_ATTEMPT = 'login_attempt'

function NewlineText({ text }) {
    const newText = text.split('\n').map(str => <p>{str}</p>)
    return newText
}

function ChangePassword({ token, userId, onChangeSuccess }) {
    
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({ status: "", message: "" })
    const [hideForm, setHideForm] = useState(false)

    const onSubmit = async (form) => {
        try {
            setAlert({ status: "", message: "" })
            const { isValid, errors } = validatePassword(form.password, form.password_confirmation)
            if(!isValid) {
                let message = ''
                errors.forEach((e) => {
                    message += e + "\n"
                })
                setAlert({ status: "danger", message })
                return
            }
            setLoading(true)

            let response = await fetch(`https://api.just-id.com/users/${userId}/change-password`, {
                method: "PUT",
                body: JSON.stringify(form),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => res.json())
            if(response.errors && response.errors.length > 0) {
                let message = ''
                response.errors.forEach((e) => {
                    if(e.email)
                        message += e.email + ','
                    if(e.password)
                        message += e.password + ','
                })
                setAlert({ status: "danger", message })
            }
            if(response.success) {
                setAlert({ status: "success", message: response.message })
                setHideForm(true)
            }
            setLoading(false)
        } catch(err) {
            if(err && err.response && err.response.data && err.response.data.errors) {
                let message = ''
                err.response.data.errors.forEach((e) => {
                    if(e.email)
                        message += e.email + ','
                    if(e.password)
                        message += e.password + ','
                })
                setAlert({ status: "danger", message })
            }
            setLoading(false)
        }
    }

    return (
        <Formik 
            initialValues={{}}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    password: Yup.string().required().label('Password'),
                    password_confirmation: Yup.string().required().label('Password'),
                })
            }
        >
            {
                ({ values, handleSubmit, handleChange, errors }) => {
                    return (
                        <form className="login-form" method="POST" onSubmit={handleSubmit}>
                            <h3 className="login-head">
                                Change Password
                            </h3>
                            {
                                alert && alert.message && alert.message.length > 0 && 
                                <div className={`alert alert-${alert.status}`}>
                                    <NewlineText text={alert.message}/>
                                    {alert.status === 'success' && <a href="#" className="" onClick={() => onChangeSuccess()}> Click here to sign-in</a>}
                                </div>
                            }
                            {
                                !hideForm &&
                                <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
                                    <label className="control-label">Password</label>
                                    <input className="form-control" type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange}/>
                                    { errors.password ? <span className="help-block">{errors.password}</span> : null }
                                </div>
                            }
                            {
                                !hideForm &&
                                <div className={`form-group ${errors.password_confirmation ? 'has-error' : ''}`}>
                                    <label className="control-label">Password Confirmation</label>
                                    <input className="form-control" type="password" placeholder="Password Confirmation" name="password_confirmation" value={values.password_confirmation} onChange={handleChange}/>
                                    { errors.password_confirmation ? <span className="help-block">{errors.password_confirmation}</span> : null }
                                </div>
                            }
                            {
                                !hideForm &&
                                <div className="form-group btn-container">
                                    <Button appearance="primary" type="submit" loading={loading ? true : false} block>Submit</Button>
                                </div>
                            }
                        </form>
                    )
                }
            }
        </Formik>
    )
}

function Login() {
    const [state, setState] = useState({
        isLoading: false,
        message: '',
        email: '',
        password: '',
        height: '390px',
        emailreset: '',
        messagereset: '',
        resetstatus: '',
        showFormReset: false
    })
    const [lock, setLock] = useState(false)
    const [lockExpired, setLockExpired] = useState(null)
    const [passExpired, setPassExpired] = useState(false)
    const [changePassData, setChangePassData] = useState({})
    useEffect(() => {
        document.getElementById('root').style.display = 'block';
        document.getElementById('page-loading').style.display = 'none';
        let exp = cookies.get(ATTEMPT_EXPIRED)
        if(exp) {
            let current = dayjs()
            let expired = dayjs(exp)
            let d = expired.diff(current, 'minute')
            if(d > 0) {
                setLock(true)
                setLockExpired(dayjs(exp).format("YYYY-MM-DD HH:mm:ss"))
            } else {
                cookies.remove(ATTEMPT_EXPIRED)
                cookies.remove(LOGIN_ATTEMPT)
            }
        }
    }, [])

    useEffect(() => {
        if(lockExpired) {
            const intervalId = setInterval(() => {  
                let current = dayjs()
                let expired = dayjs(lockExpired)
                let seconds = expired.diff(current, 'second')
                let totalMinutes = Math.floor(seconds / 60)
                let totalSeconds = seconds - (totalMinutes * 60)
                if(seconds > 0) {
                    setState(state => ({
                        ...state,
                        message: `Too many failed login attempt. Please try again in ${totalMinutes} minutes ${totalSeconds} seconds`, 
                    }))
                } else {
                    cookies.remove(ATTEMPT_EXPIRED)
                    cookies.remove(LOGIN_ATTEMPT)
                    setLock(false)
                    setLockExpired(null)
                    setState(state => ({
                        ...state,
                        message: '', 
                        height: '390px', 
                    }))
                }
            }, 1000)
            return () => clearInterval(intervalId);
        }
        
    }, [lockExpired])

    const onSubmit = async (data) => {
        setState(state => ({
            ...state,
            message: '', 
            height: '390px', 
            isLoading: true
        }))
        try {
            let res = await AuthenticateApi.login(data)
            if(res.accessToken) {
                setToken(res.accessToken)
                setTimeout(() => {
                    window.location = '/dashboard'
                }, 500);
            } 
            if(res.passwd_expired) {
                setChangePassData({ token: res.token, userId: res.userId })
                setState(state => ({
                    ...state,
                    message: `Your password has expired.`, 
                    height: '470px', 
                    isLoading: false
                }))

            }
            if(res.errors) {
                let message = ''
                res.errors.forEach((e) => {
                    if(e.email) {
                        message += e.email + ','
                        let attempt = cookies.get(LOGIN_ATTEMPT) ? cookies.get(LOGIN_ATTEMPT) + 1 : 1
                        if(attempt > 4) {
                            let expired = dayjs().add(5, 'minute')
                            cookies.set(ATTEMPT_EXPIRED, expired.format("YYYY-MM-DD HH:mm:ss"), { path: '/' })
                            cookies.set(LOGIN_ATTEMPT, 1, { path: '/' })
                            setLockExpired(expired.format("YYYY-MM-DD HH:mm:ss"))
                            setLock(true)
                        }
                        cookies.set(LOGIN_ATTEMPT, attempt, { path: '/' })
                    }
                    if(e.password) {
                        message += e.password + ','
                        if(e.lock) {
                            let expired = dayjs().add(5, 'minute')
                            cookies.set(ATTEMPT_EXPIRED, expired.format("YYYY-MM-DD HH:mm:ss"), { path: '/' })
                            cookies.set(LOGIN_ATTEMPT, 1, { path: '/' })
                            setLockExpired(expired.format("YYYY-MM-DD HH:mm:ss"))
                            setLock(true)
                        }
                    }
                })
                setState(state => ({
                    ...state,
                    message, 
                    height: '470px', 
                    isLoading: false
                }))
            }

        } catch(err) {
            console.log(err)
            setState(state => ({
                ...state,
                message: 'Login failed', 
                height: '470px', 
                isLoading: false
            }))
        }
    }

    const onChangeSuccess = () => {
        setChangePassData({})
        setPassExpired(false)
        setState(state => ({
            ...state,
            message: '', 
            height: '370px', 
        }))
    }

    return (
        <div>
            <div className="login-topbar">
                <img src={`/images/app-logo.png`} style={{width: '250px'}} alt="Logo"/>
            </div>
            
            <section className="login-content">
                <div className="login-box" style={{height: state.height}}>
                    {
                        passExpired ?
                            <ChangePassword token={changePassData.token} userId={changePassData.userId} onChangeSuccess={onChangeSuccess}/>
                        : <Formik 
                            initialValues={{}}
                            enableReinitialize
                            onSubmit={(values) => onSubmit(values)}
                            validationSchema={
                                Yup.object().shape({
                                    email: Yup.string().email('Invalid email').required().label('Email'),
                                    password: Yup.string().required().label('Password'),
                                })
                            }
                        >
                            {
                                ({ values, handleSubmit, handleChange, errors }) => {
                                    return (
                                        <form className="login-form" method="POST" onSubmit={handleSubmit}>
                                            <h3 className="login-head">
                                                SIGN IN
                                            </h3>
                                            {
                                                state.message && state.message.length > 0 ? 
                                                    <div className="alert alert-danger">
                                                        {state.message}
                                                        {changePassData.token && <a href="#" className="" onClick={() => setPassExpired(true)}> Click here to change password</a>}
                                                    </div>
                                                : null
                                            }
                                            {
                                                !lock && <>     
                                                    <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
                                                        <label className="control-label">E-Mail</label>
                                                        <input className="form-control" type="text" placeholder="Email" autoFocus name="email" value={values.email} onChange={handleChange}/>
                                                        { errors.email ? <span className="help-block">{errors.email}</span> : null }
                                                    </div>
                                                    <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
                                                        <label className="control-label">PASSWORD</label>
                                                        <input className="form-control" type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange}/>
                                                        { errors.password ? <span className="help-block">{errors.password}</span> : null }
                                                    </div>
                                                    
                                                    <div className="form-group btn-container">
                                                        {/* <Button className="btn btn-primary btn-block" type="submit" isloading={state.isLoading ? 1 : 0}><i className="fa fa-sign-in fa-lg fa-fw"></i>SIGN IN</Button> */}
                                                        <Button appearance="primary" type="submit" loading={state.isLoading ? true : false} block>SIGN IN</Button>
                                                    </div>
                                                </>
                                            }
                                            {/* <div className="form-group text-center">    
                                                <p className="semibold-text mt-10"><button className="btn btn-link" type="button" onClick={() => openForgot()}>Forgot Password ?</button></p>
                                            </div> */}

                                        </form>
                                    )
                                }
                            }
                        </Formik>
                    }
                    
                </div>
            </section>
        </div>

    )
}

export default Login