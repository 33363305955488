import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions/index'

export const useNotification = () => {
    const dispatch = useDispatch()
    const notifications = useSelector((state) => state.notifications)
    const unread = useSelector((state) => state.unread)

    const getNotifications = () => {
        dispatch(actions.getNotifications())
    }

    const readNotification = (id) => {
        dispatch(actions.readNotification(id))
    }

    return { unread, notifications, getNotifications, readNotification }
}