import axios from 'axios'
import join from 'url-join'
import { SERVICE_API } from './../constants'

axios.interceptors.request.use(async (config)=> {

  const jwtToken = localStorage.getItem('auth_token')
  if (jwtToken != null) {
      config.headers = { 'Authorization': `Bearer ${jwtToken}`, 'Content-Type': 'application/json' }
  }
  config.url = join(SERVICE_API, config.url);
  return config;
  
});

export const httpClient = axios