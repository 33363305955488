export const LOAD_DASHBOARD_SUCCESS = 'LOAD_DASHBOARD_SUCCESS';
export const LOAD_EVENT_SUCCESS = 'LOAD_EVENT_SUCCESS';
export const SELECT_EVENT_SUCCESS = 'SELECT_EVENT_SUCCESS';
export const LOAD_CUSTOMER_SUCCESS = 'LOAD_CUSTOMER_SUCCESS';
export const LOAD_LOCATION_SUCCESS = 'LOAD_LOCATION_SUCCESS';
export const SET_DEVICE_STATUS_SUCCESS = 'SET_DEVICE_STATUS_SUCCESS';
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_SOCKET_FUNCTION = 'SET_SOCKET_FUNCTION';
export const SET_SERVICE_PERMISSION_SUCCESS = 'SET_SERVICE_PERMISSION_SUCCESS';
export const SET_BREADCRUMB = 'SET_BREADCRUMB';

// Member
export const MEMBER_PENDING = 'MEMBER_PENDING'
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS'
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS'
export const STORE_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS'
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS'
// Member Setting
export const FETCH_MEMBER_SETTING_SUCCESS = 'FETCH_MEMBER_SETTING_SUCCESS';
export const UPDATE_MEMBER_SETTING_SUCCESS = 'UPDATE_MEMBER_SETTING_SUCCESS';

export const SET_SERVICE_EXPIRED = 'SET_SERVICE_EXPIRED'
export const SET_ORG_DISABLED = 'SET_ORG_DISABLED'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_UNREAD_NOTIFICATION = 'SET_UNREAD_NOTIFICATION'

export const SET_CENTRALIZES = 'SET_CENTRALIZES'

export const SET_NOTICES = 'SET_NOTICES'