import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import Button from 'rsuite/lib/Button'
import { httpClient } from 'utils/HttpClient'
import Loader from 'components/Loader'

function UserActivate() {
    const [state, setState] = useState({
        isLoading: true,
        message: "",
        success: false,
        error: false
    })
    let { code } = useParams()
    useEffect(() => {
        document.getElementById('root').style.display = 'block';
        document.getElementById('page-loading').style.display = 'none';
    }, [])

    const FetchActivate = useCallback(async () => {
        if (code) {
            try {

                let res = await (await httpClient.post('/user/activate', { code })).data
                setState((state) => ({
                    ...state,
                    isLoading: false,
                    message: res.message,
                    success: res.success
                }))
            } catch(err) {
                setState((state) => ({
                    ...state,
                    isLoading: false,
                    message: "Activate failed",
                    error: true
                }))
            }
        }
    }, [code])

    useEffect(() => {
        FetchActivate()
    }, [FetchActivate])

    return (
        <div>
            <div className="login-topbar">
                <img src={`/images/app-logo.png`} style={{width: '250px'}} alt="Logo"/>
            </div>
            
            <section className="login-content">
                <div className="login-box text-center" style={{ minHeight: state.isLoading ? 400 : 280 }}>
                    <div className="login-form">
                        <h3 className="login-head">
                            Verification
                        </h3>
                        {
                            state.success && 
                                <div className="alert alert-success">
                                    {state.message}
                                </div>
                        }
                        {
                            state.error && 
                                <div className="alert alert-error">
                                    {state.message}
                                </div>
                        }
                        {state.success && <Link to="/login">
                            <Button appearance="primary" type="button" loading={state.isLoading ? true : false} block>SIGN IN</Button>
                        </Link>}
                        
                        { state.isLoading && <Loader /> }
                    </div>
                </div>
                
            </section>
        </div>

    )
}

export default UserActivate