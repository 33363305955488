import * as types from './actionTypes';
// import LocationApi from './../api/LocationApi';

export function setServicePermissionSuccess(servicepermission) {
    return {type: types.SET_SERVICE_PERMISSION_SUCCESS, servicepermission};
}

export function setServicePermission(data) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function(dispatch) {
        return dispatch(setServicePermissionSuccess(data));
    };
}
