import React from 'react'
import { Modal } from 'rsuite';

const ModalView = ({show, onHide, title, children, footer, size = 'md'}) => {
    return (
        <div className="modal-container">
            <Modal show={show} onHide={onHide} size={size}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { children }
                </Modal.Body>
                <Modal.Footer>
                    { footer }
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default ModalView