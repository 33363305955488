import React from 'react';
import {Link} from 'react-router-dom';
import {BASE_API, SERVICE_API} from './../../constants';
import menus from './../../menu.json';


const renderMenu = (permission, currentPath, me, userPermission) => {
    // console.log(currentPath.search('service') !== -1 ? 'active' : '')
    return menus.map((menu, index) => {
        if(menu.org) {
            if(!me.app_organization_id || me.app_organization_id === 0) {
                return null
            }
        }
        if(menu.children) {
            return (
                <li key={menu.prefix + index} className={`treeview ${currentPath.search(menu.name) !== -1 ? 'active' : ''}`}>
                    <a href="#menu"><i className={menu.icon}></i> <span>{menu.title}</span><i className="fa fa-angle-right"></i></a>
                    <ul className={`treeview-menu ${currentPath.search(menu.name) !== -1 ? 'open' : ''}`}>
                        {
                            menu.children.map( (child, index) => {
                                if(child.permission) {
                                    if(typeof permission === "undefined")
                                        return null
                                    if(typeof permission[child.permission] === "undefined")
                                        return null
                                    if(!permission[child.permission])
                                        return null
                                    // if(!userPermission[child.permission])
                                    //     return null
                                }
                                // if(!userPermission[child.name])
                                    // return null
                                
                                // console.log(userPermission.indexOf(child.name))
                                if(child.children) {
                                    if(child.children.filter(c => userPermission[c.name] ? true : false ).length <= 0)
                                        return null
                                    return (
                                        <li key={child.prefix + child.title + index} className={`treeview ${currentPath.search(child.name) !== -1 ? 'active' : ''}`}>
                                            <a href="#menu"><i className={child.icon}></i><span> {child.title}</span><i className="fa fa-angle-right"></i></a>
                                            <ul className={`treeview-menu ${currentPath.search(child.name) !== -1 ? 'menu-open' : ''}`} >
                                                {
                                                    child.children.map((c, inx) => {
                                                        
                                                        return (
                                                            <li key={child.prefix + c.title + c.path + inx}>
                                                                {
                                                                    c.divider ? 
                                                                        <div style={{ marginLeft: 30, marginBottom: 5, borderBottom: '1px dashed #eee', width: 100, height: 10 }}></div>
                                                                    :
                                                                    <Link to={{pathname: `${c.path}`}}>
                                                                        <i className={c.icon}></i> <span>{c.title}</span>
                                                                    </Link>
                                                                }
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    )
                                } else {
                                    if(!userPermission[child.name])
                                        return null
                                }
                                return (
                                    <li key={menu.prefix + child.title + index}>
                                        {
                                            <Link to={{pathname: `${child.path}`}}>
                                                <i className={child.icon}></i> <span>{child.title}</span>
                                            </Link>
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </li>
            )
        }
        return (
            <li key={menu.prefix + index}>
                {
                    <Link to={{pathname: `${menu.path}`}}>
                        <i className={menu.icon}></i> <span>{menu.title}</span>
                    </Link>
                }
            </li>
        )
    })
}

const Sidebar = ({me, permission, location, userPermission}) => {

    return (
        <aside className="main-sidebar hidden-print" style={{ display: 'flex', flexDirection: 'column'}}>
            <section className="sidebar flex-1">
                <div className="user-panel">
                    <div className="pull-left image">
                        {
                            me && me.avatar ? 
                                <img className="img-circle" src={SERVICE_API + '/storages/files?q=' + me.avatar} alt="img-profile"/>
                            : <img className="img-circle" src="/images/user.png" alt="img-profile"/>
                        }
                    </div>
                    <div className="pull-left info">
                        <p>{me ? me.name : ''}</p>
                        <p className="designation">{me ? me.email:''}</p>
                    </div>
                </div>
                <ul className="sidebar-menu">
                    {
                        renderMenu(permission, location.pathname, me, userPermission)
                    }
                </ul>
            </section>
            <section className="sidebar flex-0">
                <ul className="sidebar-menu">
                    <li>
                        <Link to="/billing"><i className="fa fa-file-text"></i> <span>Billing</span></Link>
                    </li>
                    <li className="treeview">
                        <a href="#menu"><i className="fa fa-info"></i> <span>About us</span><i className="fa fa-angle-right"></i></a>
                        <ul className="treeview-menu" >
                            <li>
                                <a href="https://global-tech.me/th/terms-and-conditions/" target="_blank">Terms and conditions</a>
                            </li>
                            <li>
                                <a href="https://global-tech.me/th/privacy-policy/" target="_blank">Privacy policy</a>
                            </li>
                            <li>
                                <a href="https://global-tech.me/th/data-potection-policy/" target="_blank">Data potection policy</a>
                            </li>
                            <li>
                                <a href="https://global-tech.me/pdpa-en/" target="_blank">PDPA English</a>
                            </li>
                            <li>
                                <a href="https://global-tech.me/th/pdpa-th/" target="_blank">PDPA ไทย</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
        </aside>
    )
}


export default Sidebar;

