import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions/index'

export const useServiceExpired = () => {
    const dispatch = useDispatch()
    const serviceExpires = useSelector((state) => state.serviceExpires)

    const getServiceExpired = () => {
        dispatch(actions.getServiceExpired())
    }
    return { serviceExpires, getServiceExpired }
}