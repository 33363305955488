

export function validatePassword(password, confirmPassword) {
    const errors = []

    const lengthCriteria = password.length >= 10
    if (!lengthCriteria) {
        errors.push('Password must be at least 10 characters long.')
    }
  
    const uppercaseCriteria = /[A-Z]/.test(password)
    if (!uppercaseCriteria) {
        errors.push('Password must contain at least one uppercase letter.')
    }
  
    const lowercaseCriteria = /[a-z]/.test(password)
    if (!lowercaseCriteria) {
        errors.push('Password must contain at least one lowercase letter.')
    }
  
    const numberCriteria = /\d/.test(password)
    if (!numberCriteria) {
        errors.push('Password must contain at least one number.')
    }
  
    // const specialCriteria = /[\!\@\#\$\%\^\&\*]/.test(password)
    const specialCriteria = /[!@#$%^&*]/.test(password)
    if (!specialCriteria) {
        errors.push('Password must contain at least one special character (!@#$%^&*).')
    }
  
    const notSequentialCriteria = !isSequential(password)
    if (!notSequentialCriteria) {
        errors.push('Password must not contain sequential characters.')
    }
  
    const confirmPasswordCriteria = password === confirmPassword
    if (!confirmPasswordCriteria) {
        errors.push('Password and confirmation password do not match.')
    }
  
    const isValid = lengthCriteria && uppercaseCriteria && lowercaseCriteria && numberCriteria && specialCriteria && notSequentialCriteria && confirmPasswordCriteria;
  
    return {
        isValid: isValid,
        errors: errors
    }
}
  
function isSequential(password) {
    const numbers = password.replace(/[^0-9]/g, '')
    const numArray = numbers.split('').map(Number)
    for (let i = 0; i < numArray.length - 1; i++) {
        if (numArray[i + 1] === numArray[i] + 1) {
            return true
        }
    }
    return false
}

