import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions/index'

export const useCentralize = () => {
    const dispatch = useDispatch()
    const centralizes = useSelector((state) => state.centralizes)

    const getCentralizes = () => {
        dispatch(actions.getCentralizes())
    }

    const setOrg = (id) => {
        dispatch(actions.setCentralizeOrg(id))
    }

    return { centralizes, getCentralizes, setOrg }
}