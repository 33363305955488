
const TOKEN = 'auth_token'

export function setToken(params) {
    return localStorage.setItem(TOKEN, params)
}

export function getToken() {
    return localStorage.getItem(TOKEN)
}

export function removeToken() {
    return localStorage.removeItem(TOKEN)
}