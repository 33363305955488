import * as types from './../../actions/actionTypes';
import initialState from './initialState';

export default function servicepermissionReducer(state = initialState.servicepermission, action) {
    switch(action.type) {
        case types.SET_SERVICE_PERMISSION_SUCCESS:
            return action.servicepermission;
        default: 
            return state;
    }
}
