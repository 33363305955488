const initialState = {
    socket: null,
    dashboard: {},
    user: null,
    servicepermission: {},
    breadcrumb: [],
    locations: [],
    serviceExpires: [],
    orgDisables: [],
    notifications: [],
    unread: 0,
    centralizes: [],
    notices: []
}

export default initialState