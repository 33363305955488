import React from "react"
import { Link } from "react-router-dom"

function Breadcrumb({ breadcrumb }) {
    return (
        <div className="page-title hidden-xs">
            <div>
                <h3 className="fw-200 mb-4 mt-3" style={{color: 'dimgrey'}}>{breadcrumb && breadcrumb.length > 0 ? breadcrumb[breadcrumb.length - 1].title : ''}</h3>
            </div>
            <div>
                <ul className="breadcrumb" style={{ padding: "8px 0px" }}>
                    <li>
                        <i className="fa fa-home fa-lg"></i>
                    </li>
                    {breadcrumb
                        ? breadcrumb.map((item, index) => {
                              return (
                                  <li key={index}>
                                      {item.path === "none" ? (
                                          item.title
                                      ) : (
                                          <Link
                                              to={{
                                                  pathname: item.path,
                                                  state: {
                                                      title: item.title,
                                                  },
                                              }}
                                          >
                                              {item.title}
                                          </Link>
                                      )}
                                  </li>
                              );
                          })
                        : ""}
                </ul>
            </div>
        </div>
    )
}

export default Breadcrumb