import {SET_ORG_DISABLED} from './../../actions/actionTypes';
import initialState from './initialState';

export default function orgDisabledReducer(state = initialState.orgDisables, action) {
    switch(action.type) {
        case SET_ORG_DISABLED:
            return action.orgDisables;
        default: 
            return state;
    }
}
