import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import configureStore from './store/configureStore'
import { Provider } from 'react-redux'
import Full from './containers/Full'
import Login from './containers/Login'
import UserActivate from './containers/UserActivate'

import 'rsuite/dist/styles/rsuite-default.css'
// import './index.css'
import './App.css'
import './stylesheets/index.scss'


const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <div>
                <Router>
                    <Switch>
                        <Route path="/user/activate/:code" name="UserActivate" component={UserActivate}/>
                        <Route path="/login" name="Login" component={Login}/>
                        <Route path="/" name="Home" component={Full}/>
                    </Switch>
                </Router>
            </div>
        </Provider>
    )
}

export default App
