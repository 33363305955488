
class IDFormat
{
    static numberFixedLen(a, b) {
        return(1e13 + "" + a).slice(-b);
    }

    static event(code) {
        return this.numberFixedLen(code, 5);
    }

    static checkinEvent(event_id, code) {
        return this.event(event_id) + this.numberFixedLen(code, 5);
    }

    static checkinHotel(location_id, code) {
        return this.numberFixedLen(location_id, 5) + this.numberFixedLen(code, 5);
    }
}

export default IDFormat;