import {SET_BREADCRUMB} from './../../actions/actionTypes';
import initialState from './initialState';

export default function breadcrumbReducer(state = initialState.breadcrumb, action) {
    switch(action.type) {
        case SET_BREADCRUMB:
            return action.breadcrumb;
        default: 
            return state;
    }
}
