import * as types from './actionTypes'
import ServiceApi from 'api/ServiceApi'

export function setOrgDisabledData(disables) {
    return {type: types.SET_ORG_DISABLED, orgDisables: disables}
}

export function getOrgDisabled() {
    return async function(dispatch) {
        let exp = await ServiceApi.getOrgDisabled()
        return dispatch(setOrgDisabledData(exp.data))
    }
}