import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import dayjs from 'dayjs'
import AuthenticateApi from 'api/AuthenticateApi'
import IDFormat from 'lib/IDFormat'
import { useNotification } from 'hooks/notification'
import { useCentralize } from 'hooks/centralize'
import Modal from 'components/ModalView'
import './Header.css'

function Header({ me }) {
    const [dropdown, setDropdown] = useState(false)
    const [modal, setModal] = useState({show: false, data: {} })
    const history = useHistory()
    const { unread, notifications, readNotification } = useNotification()
    const { centralizes, setOrg } = useCentralize()

    const open = (e) => {
        e.preventDefault();
        setDropdown(!dropdown)
    }

    const logout = (e) => {
        e.preventDefault();
        AuthenticateApi.logout();
        history.replace('/login')
    }

    const viewNotification = (noti) => {
        setModal({show: true, data: noti})
        if(!noti.readed) readNotification(noti.id)
    }

    return (
        <header className="main-header hidden-print">
            <a className="logo" href="/" style={{backgroundColor: '#222d32'}} data-toggle='tooltip'>
                <img src={`/images/app-logo.png`} alt="" style={{height: '100%'}}/>
            </a>
            <nav className="navbar navbar-static-top">
                <a className="sidebar-toggle" href="#/" data-toggle="offcanvas"><i></i></a>
                <div className="navbar-custom-menu">
                    <ul className="top-nav">
                        {/* <li style={{color: '#fff', padding: '0px 5px'}}>{me && me.app_organization_id ? 'ORG ID: ORG' + IDFormat.numberFixedLen(me.app_organization_id, 6) : ''} </li> */}
                        <li className={`dropdown`}>
                            <a className="dropdown-toggle alert-button" href="#/" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style={{position: 'relative'}}>
                                {me && me.app_organization_id ? 'ORG ID: ORG' + IDFormat.numberFixedLen(me.app_organization_id, 6) : ''} 
                                { centralizes.length > 0 && <i className='fa fa-angle-down ml-2'></i> }
                            </a>
                            <ul className="dropdown-menu" style={{ maxHeight: 350, overflow: 'scroll' }}>
                                {
                                    centralizes.length > 0 && centralizes.map((a) => (
                                        <li key={a.id}>
                                            <a href="#/" onClick={() => setOrg(a.id)}>
                                                { 'ORG' + IDFormat.numberFixedLen(a.id, 6) } : {a.name}
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                        <li className={`dropdown`}>
                            <a className="dropdown-toggle alert-button" href="#/" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style={{position: 'relative'}}>
                                <i className="fa fa-bell fa-lg"></i>
                                { unread > 0 && <span className="alert-button__badge">{unread}</span> }
                            </a>
                            <ul className="dropdown-menu notification-menu">
                                {
                                    notifications && notifications.length > 0 ?
                                        notifications.map((noti, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href="#/" onClick={() => viewNotification(noti)}>
                                                        <div className="d-flex">
                                                            <div className="flex-0 pr-3">
                                                                {noti.notify_type === 'notice' && <i className="fa fa-bullhorn"></i> }
                                                                {noti.notify_type === 'billing' && <i className="fa fa-exclamation-circle"></i> }
                                                            </div>
                                                            <div className="flex-1 pl-1">
                                                                <span>{noti.title}</span>
                                                                <br />
                                                                <small>{dayjs(noti.notify_date).format("DD MMM YYYY - HH:mm")}</small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    : <li>
                                        <a href="#/">Not found</a>
                                    </li>
                                }
                                {
                                    notifications && notifications.length > 0 ?
                                        <li>
                                            <Link to="/notification" className="text-center">See all</Link>
                                        </li>
                                    : null
                                }
                            </ul>
                        </li>
                        <li className={`dropdown ${dropdown ? 'open' : ''}`}>
                            <a className="dropdown-toggle" onClick={(e) => open(e)} href="#menu" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user fa-lg"></i></a>
                            <ul className="dropdown-menu settings-menu">
                                <li><Link to={`/profile`}><i className="fa fa-user fa-lg"></i> Profile</Link></li>
                                <li><a href="#logout" onClick={(e) => logout(e)}><i className="fa fa-sign-out fa-lg"></i> Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            <Modal
                show={modal.show}
                title={modal.data.title}
                onHide={() => setModal({show: false, data: {}})}
            >
                <div className="row">
                    <div className="col-md-12" dangerouslySetInnerHTML={{ __html: modal.data.description }}>
                    </div>
                </div>
            </Modal>
        </header>
    )
}

export default Header;