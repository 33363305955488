import * as types from './actionTypes';

export function setBreadcrumbData(breadcrumb) {
    return {type: types.SET_BREADCRUMB, breadcrumb};
}

export function setBreadcrumb(data) {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return function(dispatch) {
        return dispatch(setBreadcrumbData(data));
    };
}