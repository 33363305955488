
class BaseApi {

    static serialize(obj) {
        let str = [];
        for(let p in obj)
            if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        return str.join("&");
    }

    static handleError(error) {
        // switch (error.response.status) {
        // case 401:
        //     // this.redirectTo(document, '/')
        //     // browserHistory.push('/login')
        //     break;
        // case 404:
        //     // this.redirectTo(document, '/404')
        //     break;
        // default:
        //     // this.redirectTo(document, '/500')
        //     // browserHistory.push('/login')
            
        //     break;
        // }
        return Promise.reject(error)
    }

    static fetchData(payload) {
        return fetch(payload.url, {
            method: payload.method,
            body: JSON.stringify(payload.data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
        .catch(this.handleError)
        // return axios({
        //         ...payload,
        //         // transformRequest: [function (data, headers) {
        //         //     // Do whatever you want to transform the data
        //         //     headers.common['Content-Type'] = `application/json`
        //         //     headers.common['Authorization'] = `Bearer ${cookie.load('TOKEN')}`
        //         //     return data;
        //         // }],
        //         headers: {
        //             'X-Requested-With': 'XMLHttpRequest',
        //             'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        //             // 'Content-Type': 'application/json'
        //         }
        //     })
        //     .then((res) => res.data)
        //     .catch(this.handleError)
    }

    static get(id) {
        return this.fetchData({
            method: 'GET',
            url: this.url() + '/' + id,
        })
    }

    static query(data) {
        let queryParam = data !== undefined ? '?' + this.serialize(data) : ''
        return this.fetchData({
            method: 'GET',
            url: this.url() + queryParam,
        })
    }

    static post(data) {
        return this.fetchData({
            method: 'POST',
            url: this.url(),
            data: data
        })
    }

    static put(data, id) {
        return this.fetchData({
            method: 'PUT',
            url: this.url() + '/' + id,
            data: data
        })
    }

    static delete(id) {
        return this.fetchData({
            method: 'DELETE',
            url: this.url() + '/' + id
        })
    }

    static save(data) {
        let id = data.id || null
        // let url =  data.id ? `${this.url()}/${id}` : this.url()
        let formData = {
            data: {
                type: this.type(),
                attributes: data
            }
        }
        if(id) {
            return this.put(formData, id)
        } else {
            return this.post(formData)
        }
    }

}

export default BaseApi;