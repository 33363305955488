import { httpClient } from 'utils/HttpClient'

class NotificationApi {

    async getNotifications() {
        return await (await httpClient.get('/notifications')).data
    }

    async updateNotification(id, data) {
        return await (await httpClient.put('/notifications/'+id, data)).data
    }
}

export default new NotificationApi()