import axios from 'axios'
import BaseApi from './../lib/BaseApi'
import {BASE_API, SERVICE_API} from './../constants'
import { getToken, removeToken } from './../services/token'

class AuthenticateApi extends BaseApi
{
    static me() {
        return fetch(SERVICE_API + '/authenticate/me', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }
        }).then(function(response) {
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            return response.json();
        })
    }

    static login(data) {
        return fetch(SERVICE_API + '/security/auth/login', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json())
    }

    static logout() {
        removeToken()
    }

    static updateprofile(data) {
        let formData = {
            data: {
                type: 'profile',
                attributes: data
            }
        }
        return fetch(BASE_API + '/api/profile', {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json',
                "AUTHORIZATION": `Bearer ${getToken()}`
            }
        }).then(res => res.json())
    }

    static uploadavatar(file) {
        const formData = new FormData();
        formData.append('avatar', file);
        return this.fetchData({
            method: 'POST',
            url: `${BASE_API}/api/profile/avatar`,
            data: formData
        })
    }

    static uploadnamecard(file) {
        const formData = new FormData();
        formData.append('namecard', file);
        return this.fetchData({
            method: 'POST',
            url: `${BASE_API}/api/profile/namecard`,
            data: formData
        })
    }

    static uploadnamecardback(file) {
        const formData = new FormData();
        formData.append('namecard_back', file);
        return this.fetchData({
            method: 'POST',
            url: `${BASE_API}/api/profile/namecardback`,
            data: formData
        })
    }

    static uploadwechatqr(file) {
        const formData = new FormData();
        formData.append('wechatqr', file);
        return this.fetchData({
            method: 'POST',
            url: `${BASE_API}/api/profile/wechatqr`,
            data: formData
        })
    }

    static passwordReset(data) {
        return axios.post('/api/password/reset', {email: data}, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.data)
    }

    static changepass(data) {
        return axios.post('/api/profile/changepassword', data, {
            headers: {
                'Content-Type': 'application/json',
                "AUTHORIZATION": `Bearer ${getToken()}`
            }
        }).then(res => res.data)
    }
}

export default AuthenticateApi;