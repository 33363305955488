import {combineReducers} from 'redux';
import socket from './socketReducer';
import user from './userReducer';
import breadcrumb from './breadcrumbReducer';
import servicepermission from './servicepermissionReducer';
import serviceExpires from './serviceExpiredReducer';
import orgDisables from './orgDisabledReducer';
import centralizes from './centralizeReducer';
import notifications from './notificationReducer';
import unread from './unreadReducer';
import notices from './noticeReducer';

const rootReducer = combineReducers({
  // short hand property names
  servicepermission,
  socket,
  user,
  breadcrumb,
  serviceExpires,
  orgDisables,
  notifications,
  unread,
  centralizes,
  notices,
});

export default rootReducer;